function eventBind(){
  setMenuEvent()
  if(location.pathname === '/'){
    setSlider()
    setFollowBnrEvent()
  }
}

eventBind()

// ===========================
// スライダー設定
// ===========================
function setSlider(){
  const sliderElm = document.getElementById('splideSlider')
  if(sliderElm){
    new Splide('#splideSlider', {
      type: 'loop',
      perPage: 1,
      speed: 700,
      gap: "32px",
      autoWidth: true,
      classes: {
        pagination: 'splide__pagination',
        page      : 'splide__pagination__page',
      },
    }).mount();
  }
}

// ===========================
// メニュー機能
// ===========================
function setMenuEvent(){
  const menuElm = document.getElementById('menu')
  const menuBtnElm = document.getElementById('menuBtn')
  menuBtnElm.addEventListener('click', () => {
    if(!menuBtnElm.classList.contains('is-active')){
      menuBtnElm.classList.add('is-active')
      menuElm.classList.add('is-active')
    }else{
      menuBtnElm.classList.remove('is-active')
      menuElm.classList.remove('is-active')
    }
  })
  const menuLinkElmAry = document.querySelectorAll('.header__menu__link')
  menuLinkElmAry.forEach((elm) => {
    elm.addEventListener('click', () => {
      menuBtnElm.classList.remove('is-active')
      menuElm.classList.remove('is-active')
    })
  });
}

// ===========================
// 追従バナー処理
// ===========================
function setFollowBnrEvent(){
  const followBnrWrapElm = document.getElementsByClassName('top__follow-bnr--wrap')[0]
  const footerElmAry = document.querySelectorAll('#footer')
  const options = {
    root: null,
    rootMargin: '0px 0px',
    threshold: 0
  };
  const observer = new IntersectionObserver(intersect, options);
  footerElmAry.forEach(target => {
    observer.observe(target);
  });
  function intersect(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        followBnrWrapElm.classList.add('is-deactive')
      } else {
        followBnrWrapElm.classList.remove('is-deactive')
      }
    });
  }
}